import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { TextPlugin } from 'gsap/TextPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, TextPlugin);

export { gsap, Draggable };


// import Scrollbar from 'smooth-scrollbar';
// import App from '../app';

// const options = {
//   damping: 0.05,
// };

// class Scroll {
//   scrollbar: any;

//   constructor() {
//     this.scrollbar = Scrollbar.init(
//       document.querySelector('#my-scrollbar'),
//       options
//     );
//   }
// }

// export default Scroll;