import barba from '@barba/core';
import slide from './js/fade';
import { gsap } from './js/_scroll';
import Alpine from 'alpinejs'
window.Alpine = Alpine;

class App {
  static start() {
    return new App();
  }

  constructor() {
    Promise.all([App.domReady()]).then(this.init.bind(this));
  }

  static domReady() {
    return new Promise((resolve) => {
      document.addEventListener('DOMContentLoaded', resolve);
    });
  }

  static showPage() {
    document.documentElement.classList.remove('dom-is-loading');
    document.documentElement.classList.add('dom-is-ready');
  }

  init() {
    console.info('🚀App:init');

    // Avoid "blank page" on JS error
    try {
    
      gsap.utils.toArray( document.getElementsByClassName('has-trigger')).forEach( (el, i) => {
        gsap.to(el, {
          scrollTrigger: {
            trigger: el,
            markers:true,
            toggleClass: 'is-visible',
            onUpdate: self => console.log("direction:", self.direction)
          }
        })
      });

    } catch (err) {
      console.error(err);
    }

    App.showPage();
    Alpine.start();

  //   const acceptangeInputs = document.querySelectorAll('.input-acceptance');

  //   if( acceptangeInputs.length > 0 ) {

  //     Array.from(acceptangeInputs).forEach(input => {
  //       input.addEventListener('click', (event) => {

  //         const checkbox = event.target.querySelector('input[type=checkbox]');
  //         console.log(event.target, checkbox, checkbox.checked);

  //         checkbox.checked = !checkbox.checked
  //         event.target.classList.toggle('checked', checkbox.checked);

  //       });
  //   });
  // }
  }
}

document.documentElement.classList.remove('has-no-js');
document.documentElement.classList.add('has-js');
document.documentElement.classList.add('dom-is-loading');

App.start();

export default App;