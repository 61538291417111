import gsap from 'gsap';

export default (targets, step) => {
  const duration = 1000;
  const from = step === 'leave' ? 1 : 0;
  const to = step === 'leave' ? 0 : 1;

  console.log(step);
  console.log(from);
  console.log(to);

  return gsap.fromTo(
    targets,
    {
      opacity: from,
    },
    { opacity: to }
  );
};